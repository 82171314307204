
.footer-container {
    background-color: #5e5e5e;
}

.footer {
    padding-top: 48px;
    /* padding-bottom: 48px; */
    background-color: transparent;
    height: auto;
    display: block;
}

.footer-content1 .top-part a {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    margin-right: 16px;
    line-height: 200%;
}

.footer-content1 .bottom-part a {
    font-size: 14px;
    color: #adadad;
    text-decoration: none;
    margin-right: 16px;
    line-height: 200%;
}

.footer-content2 {
    font-size: 14px;
    color: #adadad;
    text-align: right;
    margin-top: 4px;
}