.old-container-fluid {
  width: 100%;
}

.old-container-fluid {
  font-family: Roboto, sans-serif;
}

.sky-header-underline {
  background-color: #fafafa;
}

.header {
  background: #fafafa;
}

.header ul {
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.header .search {
  position: relative;
  margin-left: 24px;
  margin-right: 24px;
  width: 526px;
}

.header .search input {
  padding: 10px 86px 10px 24px;
  background-color: #efeff0;
  border: 1px solid #efeff0;
  width: 100%;
  height: 48px;
  font-size: 16px;
  color: #000;
  outline: none;
  border-radius: 24px;
  box-sizing: border-box;
}

.header .search input::placeholder {
  color: #B9B9B9;
}

.header .search input:focus {
  background-color: #fff;
  border-color: #fff;
}

.header .search input:active {
  background-color: #fff;
  border-color: #fff;
}

.header .search input.search-not-empty:not(:focus) {
  background-color: #fff;
  border-color: #8c8c8c;
}

.header .search.authorized {
  width: 438px;
}

.header .search {
  z-index: 50;
}

.header .search input.hints-found {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}


.header .search svg circle {
  fill: none;
}

.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.bottom-panel-authorized {
  visibility: visible;
}


.header-menu {
  position: relative;
}

.header-menu li {
  display: inline-block;
  padding: 14px 0 16px;
  color: #757575;
}

.header-menu a {
  font-size: 16px;
  line-height: 20px;
  color: #757575;
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
}

.header-menu li {
  line-height: normal;
}

.header-menu>li:not(:first-child) {
  margin-left: 24px;
}

.header-menu>li a:hover {
  color: #333;
}

.header-menu>li {
  /* border-top: 3px solid #fafafa; */
  cursor: pointer;
  box-sizing: border-box;
}

.header-menu>li.page-active {
  border-top: 3px solid #4182aa;
  cursor: pointer;
}

.header-menu>li.page-active>a {
  color: #202020;
}


.header-content {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 24px;
  position: relative;
}

.logo {
  min-width: 83px;
}

.header-content {
  box-sizing: border-box;
}

.header-content {
  padding-bottom: 0 !important;
}


.header .search {
  position: relative;
  margin-left: 24px;
  margin-right: 24px;
  width: 526px;
}

.header .search input {
  padding: 10px 86px 10px 24px;
  background-color: #efeff0;
  border: 1px solid #efeff0;
  width: 100%;
  height: 48px;
  font-size: 16px;
  color: #000;
  outline: none;
  border-radius: 24px;
  box-sizing: border-box;
}

.header .search input::placeholder {
  color: #B9B9B9;
}

.header .search input:focus {
  background-color: #fff;
  border-color: #fff;
}

.header .search input:active {
  background-color: #fff;
  border-color: #fff;
}

.header .search input.search-not-empty:not(:focus) {
  background-color: #fff;
  border-color: #8c8c8c;
}

.header .search.authorized {
  width: 438px;
}

.header .search {
  z-index: 50;
}

.header .search input.hints-found {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ng-search-ico {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  padding: 7px 6px 6px 7px;
  place-items: center;
  display: inline-grid;
  cursor: pointer;
  color: #8c8c8c;
  z-index: 51;
}

.ng-search-ico:hover {
  color: #4182aa;
  background-color: #f4f4f4;
}

.header .search svg circle {
  fill: none;
}


.typeahead {
  position: absolute;
  top: 48px;
  background: #fff;
  border-top: none;
  box-sizing: border-box;
  box-shadow: 0 10px 10px rgba(73, 88, 98, 0.1);
  border-radius: 0 0 24px 24px;
  width: 100%;
  padding: 0 0 16px 0;
  z-index: 51;
  overflow: hidden;
}

.typeahead .item {
  padding: 8px 24px;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  color: #757575;
}

.typeahead .item.active {
  background-color: #f4f4f4;
}


.o-avatar-frame {
  border: 1px solid #c7c7c7;
  background-color: #fff;
  border-radius: 50%;
  padding: 2px 2px;
  margin-right: 9px;
  margin-left: 9px;
}

.o-avatar-frame img {
  border-radius: 50%;
  height: 42px;
  width: 42px;
  margin-right: 0;
}

.menu-avatar {
  font-size: 15px;
  color: #363636;
}

.menu-avatar:hover {
  color: #000000;
}

.o-avatar-frame:hover {
  border: 1px solid #919191;
}

.o-avatar-frame:active {
  border: 1px solid #525252;
}


.sky-avatar-frame {
  border: 1px solid #b9b9b9;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 8px;
  height: 40px;
  width: 40px;
}

.sky-avatar-frame img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-right: 0;
  cursor: pointer;
}

.avatar-name {
  position: relative;
  cursor: pointer;
}

.avatar-name .name {
  font-size: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #757575;
  cursor: pointer;
}

/*.avatar-name:after {*/
/*  font-family: 'IcoFont' !important;*/
/*  speak: none;*/
/*  font-style: normal;*/
/*  font-weight: normal;*/
/*  font-variant: normal;*/
/*  text-transform: none;*/
/*  line-height: 1;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  position: absolute;*/
/*  right: -16px;*/
/*  top: 6px;*/
/*  font-size: 12px;*/
/*  cursor: pointer;*/
/*  color: #757575;*/
/*}*/

.avatar-name.collapsed .menu-arrow {
  transform: rotate(180deg);
  transition: transform .3s;
}

/*.avatar-name:after {*/
/*  content: "\ea99";*/
/*}*/
/*.avatar-name.collapsed:after {*/
/*  content: "\eaa1";*/
/*}*/
.ang-avatar-dropdown-block {
  padding: 24px 0 0 0;
  position: absolute;
  right: -18px;
  width: 280px;
  top: 20px;
  z-index: 1000;
  box-sizing: border-box;
  display: none;
}

.ang-avatar-dropdown {
  background: #fff;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0 0 18px rgba(0, 0, 0, .1);

}

.ang-avatar-dropdown .dropdown-name {
  display: inline-flex;
}

.ang-avatar-dropdown hr {
  margin-bottom: 16px;
}

.ang-avatar-dropdown div:not(:last-child) {
  margin-bottom: 16px;
}

.ang-avatar-dropdown a {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #4182aa;
  line-height: 20px;
}

.ang-avatar-dropdown a:hover {
  text-decoration: none;
  color: #002967;
}

.ang-avatar-dropdown img {
  height: 20px;
  margin-left: 8px;
}

.dropdown-name {
  display: inline-block;
  font-size: 16px;
  color: #202020;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 4px !important;
}

.dropdown-name>span:first-child {
  vertical-align: middle;
  display: inline-block;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-mail {
  font-size: 16px;
  line-height: 20px;
  color: #5E5E5E;
  margin-bottom: 16px;
  word-break: break-all;
  cursor: default;
}

.dropdown-name,
.dropdown-name:hover {
  cursor: default;
}

.avatar-menu-ul li:not(:last-child) {
  margin-bottom: 7px;
}

.avatar-menu-ul li:last-child a:hover {
  color: #4f4f4f !important;
}

.dropdown-menu li {
  padding: 0;
}

.notification a {
  color: inherit;
}

/* dark theme implementation */
.theme-dark {
  background-color: #333;
}

.theme-dark .header {
  background-color: #333;
}

.theme-dark .search-result::placeholder {
  color: #04D3F7 !important;
}

.theme-dark .search-result {
  background-color: #222 !important;
  color: white !important;
  border-radius: 0;
}

[data-theme="theme-dark"] .header-menu a {
  /* background: var(--form-background); */
  color: var(--text-primary);
}

[data-theme="theme-dark"] .header-menu a:hover {
  color: var(--accent);
}

[data-theme="theme-dark"] .header-content .avatar-name .name {
  color: var(--accent);
}

[data-theme="theme-dark"] .header-content .ang-avatar-dropdown {
  background-color: var(--form-background);
}

[data-theme="theme-dark"] .header-content .dropdown-name {

  color: var(--text-secondary);
}

[data-theme="theme-dark"] .header-content .dropdown-mail {
  color: var(--text-primary);
}

[data-theme="theme-dark"] .header-content .dropdown-link a{
  color: var(--text-secondary);
}
[data-theme="theme-dark"] .header-content .dropdown-link:hover a{
  color: var(--text-primary);
}
